<template>
	<div class="container">
		<Header :flag="flag" />	
		<div class="banner">
			<div class="banner-content">
				<span class="banner-text">
				 蓝莓科技
				 </span>	
				 <br/>
				<span class="banner-subtitle-text">
				期待与你共创未来
				</span>	
			</div>
		</div>
		<div class="main">
			<div class="about">
				<div class="about-title">联系我们</div>
				<div class="product">
					<span class="title">产品咨询</span>
					<br/>
					<span class="content">
						{{data.productIntro}}
					</span>
				</div>
				<div class="product">
					<span class="title">代理合作咨询</span>
					<br/>
					<span class="content">
						{{data.agentIntro}}
					</span>
				</div>
				<div class="product">
					<span class="title">公司地址</span>
					<br/>
					<span class="content">
						{{data.address}}
					</span>
				</div>
				<div class="address" id="map">
					
				</div>
			</div>
		</div>
		<Footer />	
	</div>
</template>

<script>
	import MapLoader from '@/plugins/amap.js'	
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	export default {
		metaInfo: {
			title: '蓝莓科技-用智能的销售、全国企业大数据和AI帮助企业全方位提升销售效率|关于我们',
			meta: [
			  {
				name: 'keywords',
				content: ''
			  },
			  {
				name: 'description',
				content: '蓝莓科技是专业的智能销售SaaS服务提供商，致力于用大数据和AI技术帮助企业全面提升销售效率。'
			  }
			]
		},
		name: 'AboutUs',
		components: {
			Header,
			Footer
		},	
		data(){
			return{
				flag:'us',
				data: [],
				map: null
			}
		},
		created() {
			this.getDetail();
			this.init();
		},
		methods:{
			async getDetail(){
			   await this.$http.getCompanyParam().then(res => {
					if(res.data!=null){
						this.data = res.data;
					}
				})
			},
			async init(){
			  let that = this
			 await MapLoader('D4840aeO2I64lG1QjTqZBEwL').then(AMap => {
				 // 百度地图API功能
				var map = new BMap.Map("map");
				// map.enableScrollWheelZoom(); //启用滚轮放大缩小，默认禁用
				// map.enableContinuousZoom(); //启用地图惯性拖拽，默认禁用
				var point = new BMap.Point(this.data.longitude,this.data.latitude);    
				map.centerAndZoom(point, 18);    
				var marker = new BMap.Marker(point);        // 创建标注    
				map.addOverlay(marker);                     // 将标注添加到地图中 
			  }, e => {
			  })
			}  
		}
	}	
</script>

<style scoped lang="less">
	.banner{
		 height: 360px;
		 background-image: url(https://file.lanmeihulian.com/ZS_BANNKER2.png);
		 background-repeat: no-repeat;
		 background-size:100% 100%;
		 .banner-content{
			 width: 1200px;
			 margin: auto;
			 text-align: left;
			 .banner-text{
				 display:inline-block;
				 margin-top: 77px;
				 text-align: center;
				 font-size: 60px;
				 font-weight: 600;
				 line-height: 100px;
				 color: white;
			 }
			 .banner-subtitle-text{
				 display:inline-block;
				 text-align: center;
				 font-size: 28px;
				 color: white;
			 }
		 }
	}
	.main{
		min-height: 600px;
		height:auto !important;
		padding-top: 100px;
		.about{
			width: 1000px;
			height: auto;
			margin: auto;
			.about-title{
				background-image: url(../assets/img/FONT_CONNECTUS.png);
				background-repeat: no-repeat;
				padding-top: 20px;
				height: 120px;
				width: 330px;
				font-size: 38px;
				font-weight: 600;
				color: #131313;
			}
			.product{
				margin-top: 47px;
				.title{
					font-size: 20px;
					font-weight: 600;
					line-height: 25px;
					color: #217bfb;
				}
				.content{
					margin-top: 20px;
					display: inline-block;
					width: 580px;
					line-height: 30px;
				}
			}
			.address{
				margin-top: 40px;
				height: 400px;
				margin-bottom: 40px;
			}
		}
	}
</style>
